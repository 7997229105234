<ng-container *transloco="let t">
  <h2 mat-dialog-title class="mb-0 text-center">{{t('auth.title.almost_done_one_more_step')}}</h2>
  <p class="text-center">{{t('auth.title.select_account_type')}}</p>
  <mat-dialog-content class="pb-4 mt-6">
    <ul class="flex flex-col justify-around gap-2 items-center w-full">
      <li class="type w-full">
        <ng-container *ngTemplateOutlet="role;context:{roleId:2}"></ng-container>
      </li>
      <li class="type w-full">
        <ng-container *ngTemplateOutlet="role;context:{roleId:1}"></ng-container>
      </li>
    </ul>
    <ng-template #role let-roleId="roleId">
      <div class="custom-radios">
        <input [formControl]="roleCtrl" name="roleCtrl" class="hidden m-auto" type="radio" id="role{{ roleId }}"
          name="role" [value]="roleId">
        <label for="role{{ roleId }}">
          <div
            class="role-item relative rounded-md cursor-pointer flex items-center justify-start gap-2 px-4 py-4 shadow-md w-full">
            <div class="flex flex-row justify-between w-full items-center">
              <div class="flex flex-col gap-0.5">

                <ng-container *ngIf="roleId == 2">
                  <h4 class="text-base font-medium">{{t('personal_account')}}
                    <span class="text-xs secondary-text sm:block"> ({{t('auth.title.for_normal_users')}})</span>
                  </h4>
                  <p class="text-xs secondary-text mt-1 gap-1 flex items-center">
                    {{t('auth.title.to_use_services_provided_by_business')}}
                  </p>
                </ng-container>
                <ng-container *ngIf="roleId == 1">
                  <h4 class="text-base font-medium">{{t('business_account')}}
                    <span class="text-xs secondary-text sm:block"> ({{t('auth.title.for_business_owners')}})</span>
                  </h4>
                  <p class="text-xs secondary-text mt-1 gap-1 flex items-center">
                    {{t('auth.title.to_provide_services_to_your_customers')}}
                  </p>
                </ng-container>

              </div>
              <div>
                <span class="img-span">
                  <img src="assets/icons/check-icn.svg" alt="Checked Icon" />
                </span>
              </div>
            </div>
          </div>
        </label>
      </div>
    </ng-template>
  </mat-dialog-content>
  <div mat-dialog-actions align="end">
    <button mat-button class="success-button add-round-button" [disabled]="roleCtrl.invalid" cdkFocusInitial
      (click)="register()">
      <span *ngIf="!isLoading">{{t('finish')}}</span>
      <div *ngIf="isLoading" style="padding: 8px;">
        <mat-spinner style="margin: auto;" [diameter]="20"></mat-spinner>
      </div>
    </button>
  </div>
</ng-container>