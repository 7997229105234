/**
 * Copyrights Reserved 2023 SPARK Technologies!
 *
 * @author SPARK Technologies
 * @category Register
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.1.0
 *
 */

import { ChangeDetectorRef, Component, OnInit, Optional, ViewChild } from '@angular/core';
import { Auth, GoogleAuthProvider, signInWithPopup } from '@angular/fire/auth';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { NgOtpInputComponent } from 'ng-otp-input';
import { CountdownComponent } from 'ngx-countdown';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, distinctUntilChanged, takeUntil, takeWhile, tap, timer } from 'rxjs';
import { SparkConfigService } from 'src/@spark/services/config/config.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { CommonApiService } from 'src/app/core/common-service/common-api.service';
import { UtilityService } from 'src/app/core/common-service/utility.service';
import { LocationService } from 'src/app/core/location/location.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  orgUrl = environment.orgURL

  registerForm: FormGroup;
  otpCtrl: FormControl = new FormControl('', [Validators.required, Validators.minLength(6)])

  roleId: any = '2';
  redirectUrl: any = '/';
  config: any;
  timer: any = 3;

  userPrefixes: any;
  countryCodes: any;
  selectedCountryCode: any;
  countries: any;

  userDetailsKey;
  userLoginKey;
  otpKey: any;

  isRegisterLoading = false;
  isPageLoading = true
  passwordHide = true;
  isRegisterComplete = false
  isBadRequest = false

  isOTPExpires = false
  isSendingOTP: boolean;
  isOTPSent: boolean;
  isResendError: boolean;
  isVerifyingOTP: boolean;
  isVerifyComplete = false
  verifySuccessStatus: boolean;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  redirectPageUrl: any = '/';

  personalKey = this._utility.encrypt("personal")
  businessKey = this._utility.encrypt("business")

  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  @ViewChild(NgOtpInputComponent, { static: false }) ngOtpInput: NgOtpInputComponent;
  locationInfo: any;

  constructor(
    private _toastr: ToastrService,
    private _configService: SparkConfigService,
    private _fb: FormBuilder,
    private _authService: AuthService,
    private router: Router,
    private _cdr: ChangeDetectorRef,
    private utility: UtilityService,
    private _activeRoute: ActivatedRoute,
    private _commonApiService: CommonApiService,
    private _utility: UtilityService,
    private _translocoService: TranslocoService,
    private ngxService: NgxUiLoaderService,
    private _locationService: LocationService,

  ) {

    let params = this._activeRoute.snapshot.queryParams;
    if (params['reference']) {
      this.redirectPageUrl = params['reference']
    } else {
      this.redirectPageUrl = environment.myaccountDomain;
    }
    this._activeRoute.params
      .subscribe(
        (params: Params) => {
          if (params['roleId']) {
            this.roleId = params['roleId'] == _utility.encrypt("personal") ? 2 : params['roleId'] == _utility.encrypt("business") ? 1 : null;
            if (this.roleId == 1 || this.roleId == 2) {
              this.isBadRequest = false
            }
            else {
              this.isBadRequest = true
            }
          }
        }
      );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
    this.ngxService.startLoader('loader-01');
    this.registerForm = this._fb.group({
      'first_name': ['', Validators.required],
      'last_name': ['', Validators.required],
      'mobile_no': ['', [Validators.required, Validators.minLength(10), Validators.pattern('^[0-9]+$')]],
      'username': ['', Validators.required],
      'country_drc_key': ['99', Validators.required],
      'prefix_drc_key': [1, Validators.required],
      'i_agree': ['', [Validators.requiredTrue]],
      'user_role_drc_key': [this.roleId, [Validators.required]],
      'ref_url': [this.redirectPageUrl, '']
    })

    if (this.roleId == 1) {
      this.registerForm.addControl('email_id', new FormControl([''], [Validators.required, Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")]))
    }

    this.registerForm.get('mobile_no')?.valueChanges.subscribe((val) => {
      this.registerForm.get('username')?.patchValue(val);
    })

    this._locationService.getPosition().then((result) => {
      if (result) {
        this.locationInfo = result
      }
    })

    Promise.all([
      this._commonApiService.getPrefixes().then((data: any) => {
        this.userPrefixes = data;
      }),
      this._commonApiService.getCountryCode().then((data: any) => {
        this.countryCodes = data;
        this.selectedCountryCode = data.find(c => c.selected).country_drc_key;
      }),
    ]).finally(() => {
      this.isPageLoading = false
      this.ngxService.stopLoader('loader-01');
    }).catch(() => {
      //this.isPageError = true;
    })

    this._configService.config$.pipe(distinctUntilChanged()).subscribe((config: any) => {
      this.config = config;
    });
  }

  onDestroy() {
    this._unsubscribeAll.unsubscribe()
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Operations
  // -----------------------------------------------------------------------------------------------------

  register() {
    if (this.registerForm.invalid) {
      this.registerForm.markAllAsTouched()
      return;
    }
    this.isRegisterLoading = true;
    this._authService.signUp(this.registerForm.value).then((data: any) => {
      this._utility.showSuccess(this._translocoService.translate("auth.messages.success.registered_successfully"))
      this.otpKey = data.data.otp_key;
      this.userDetailsKey = data.data.user_details_key
      this.userLoginKey = data.data.user_login_key
      this.isRegisterComplete = true;
      this._cdr.detectChanges()
      this.countdown.begin()
      if (!environment.production)
        this.ngOtpInput.setValue(data.data.verification_code)
    }).catch((error) => {
      this.isRegisterComplete = false
      if (error.status_code == 608) {
        for (var key in error.data) {
          if (!this.registerForm.get(key)) {
            this._utility.showFailure(error.data[key][0])
          }
          else {
            this.registerForm.get(key)?.setErrors({ serverType: { message: error.data[key][0] } })
          }
          this.registerForm.markAllAsTouched()
        }
      }
    }).finally(() => {
      this.isRegisterLoading = false;
    })
  }

  /**
   * Re Send OTP
   */
  resendOTP() {
    this.isSendingOTP = true
    this.isOTPExpires = false
    this.ngOtpInput.setValue('')

    let data = {
      'user_details_key': this.userDetailsKey,
      'otp_type': 2,
    }
    this._authService.resendRegisterOTP(data).then((data: any) => {
      if (data.data) {
        this.isOTPSent = true
        this.isResendError = false
        this.otpKey = data.data.otp_key
        this.countdown.begin();
        if (!environment.production)
          this.ngOtpInput.setValue(data.data.verification_code)
      } else {
        this._utility.showFailure(data.message)
        this.isResendError = true
        this.isOTPSent = false
      }
    }).finally(() => {
      this.isSendingOTP = false
    }).catch((error) => {
      this.isResendError = true
      this.isOTPSent = false
    })
  }

  /**
   * Verify OTP
   */
  verifyOTP() {
    this.isVerifyingOTP = true
    let data = {
      'verification_code': this.otpCtrl.value,
      'mobile_no': this.registerForm.get('mobile_no')?.value,
      'username': this.registerForm.get('mobile_no')?.value,
      'user_login_key': this.userLoginKey,
      'user_details_key': this.userDetailsKey,
      'otp_key': this.otpKey,
      'otp_type': 2,
      'latitude': this.locationInfo?.latitude ?? "",
      'longitude': this.locationInfo?.longitude ?? ""
    }
    this._authService.verifyOTP(data).then((data: any) => {
      if (data.success == 1) {
        this.isVerifyComplete = true
        this.otpKey = ''
        this.userDetailsKey = ''
        this.userLoginKey = ''
        if (!this.isOTPExpires)
          this.countdown.stop()
        setTimeout(() => {
          this.verifySuccessStatus = true;
          timer(1000, 1000)
            .pipe(
              takeWhile(() => this.timer > 0),
              tap(() => this.timer--)
            )
            .subscribe(() => {
              if (this.timer == 0) {
                let creds = this._utility.getCookie("personalSecret")
                let accData = JSON.parse(this._utility.decryptAES(creds))
                let index = accData.findIndex((obj) => obj.username == this.registerForm.value.username);
                var newUrl = new URL(this.redirectPageUrl);
                newUrl.searchParams.set("accIndex", (index >= 0 ? index : 0));
                window.location.replace(newUrl.href)
              }
            });
        }, 200)
      }
      else {
        this._utility.showFailure(data.message)
        this.isVerifyComplete = false
      }
    }).finally(() => {
      this.isVerifyingOTP = false
    }).catch((error) => {
      this.isVerifyComplete = false
    })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Events
  // -----------------------------------------------------------------------------------------------------

  // select country code
  changeCountry(code) {
    this.selectedCountryCode = code.country_drc_key;
  }

  handleEvent(event) {
    if (event.action == 'done') {
      this.isOTPExpires = true
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Utility
  // -----------------------------------------------------------------------------------------------------

  // get username from email
  getUsername() {
    if (this.registerForm.get('username')?.value.length == 0) {
      var nameReplace = this.registerForm.get('email_id')?.value.toString().replace(/@.*$/, "");
      this.registerForm.get('username')?.setValue(nameReplace.toString().toLowerCase());
    }
  }
}
