/**
 * Copyrights Reserved 2023 SPARK Technologies!
 *
 * @author SPARK Technologies
 * @category Layout
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { OverlayContainer } from '@angular/cdk/overlay';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserService } from '../core/user/user.service';
import { AuthService } from '../core/auth/auth.service';
import { SparkConfigService } from 'src/@spark/services/config/config.service';
import { DOCUMENT, formatDate } from '@angular/common';
import { Subject } from 'rxjs';
import { Idle } from '@ng-idle/core';
import { MatDialog } from '@angular/material/dialog';
import { TailwindService } from 'src/@spark/services/tailwind.service';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  config;
  userData;
  isLoading;
  scheme: 'dark' | 'light';
  layout;
  currentYear = new Date().getFullYear()

  orgURL = environment.orgURL
  appVersion = environment.appVersion;

  theme: any[] = [];
  themes: [string, any][];

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _router: Router,
    private _sparkConfigService: SparkConfigService,
    private _authService: AuthService,
    private _userService: UserService,
    private _activatedRoute: ActivatedRoute,
    private _tailwindConfigService: TailwindService,
    public dialog: MatDialog,
    @Inject(DOCUMENT) private _document: any,
    private overlayContainer: OverlayContainer) {
  }

  ngOnInit() {
    // Get the themes
    this._tailwindConfigService.tailwindConfig$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((config) => {
        this.themes = Object.entries(config.themes);
      });

    this._sparkConfigService.config$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((config: any) => {
        this.config = config;
        this.scheme = config.scheme;
        this.theme = config.theme;
        this._updateScheme();
        this._updateTheme();
      });

    this._router.events.subscribe(() => {
      this._updateLayout();
    })

    this._userService.user$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((data) => {
        this.userData = data
      })
  }

  private _updateScheme(): void {
    this._document.body.classList.remove('light', 'dark');
    if (this.scheme == 'dark' ||
      this.scheme == 'light') {
      this._document.body.classList.add(this.scheme);
      this.overlayContainer.getContainerElement().classList.add(this.scheme);
    }
    else {
      this._document.body.classList.add('light');
      this.overlayContainer.getContainerElement().classList.remove(this.scheme);
    }
  }

  private _updateLayout(): void {

    if (
      this.config.layout == 'empty' ||
      this.config.layout == 'default') {
      let route = this._activatedRoute;
      while (route.firstChild) {
        route = route.firstChild;
      }
      this.layout = this.config.layout;
      const layoutFromQueryParam = (route.snapshot.queryParamMap.get('layout'));
      if (layoutFromQueryParam) {
        this.layout = layoutFromQueryParam;
        if (this.config) {
          this.config.layout = layoutFromQueryParam;
        }
      }

      // 3. Iterate through the paths and change the layout as we find
      // a config for it.
      //
      // The reason we do this is that there might be empty grouping
      // paths or componentless routes along the path. Because of that,
      // we cannot just assume that the layout configuration will be
      // in the last path's config or in the first path's config.
      //
      // So, we get all the paths that matched starting from root all
      // the way to the current activated route, walk through them one
      // by one and change the layout as we find the layout config. This
      // way, layout configuration can live anywhere within the path and
      // we won't miss it.
      //
      // Also, this will allow overriding the layout in any time so we
      // can have different layouts for different routes.

      const paths = route.pathFromRoot;
      paths.forEach((path) => {
        // Check if there is a 'layout' data
        if (path.routeConfig && path.routeConfig.data && path.routeConfig.data['layout']) {
          // Set the layout
          this.layout = path.routeConfig.data['layout'];
        }
      });

    }
    else {
      this.layout = 'default';
    }
  }

  private _updateTheme(): void {
    // Find the class name for the previously selected theme and remove it
    this._document.body.classList.forEach((className: string) => {
      if (className.startsWith('theme-')) {
        this._document.body.classList.remove(className, className.split('-')[1]);
      }
    });

    // Add class name for the currently selected theme
    this._document.body.classList.add(`theme-${this.theme}`);
  }


}
