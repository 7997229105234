import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationModule } from './components/navigation/navigation.module';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SparkConfirmationModule } from './services/confirmation/confirmation.module';
import { TailwindService } from './services/tailwind.service';
import { SparkSplashScreenModule } from './services/splash-screen/splash-screen.module';
import { DirectivesModule } from './directives/directives.module';
import { IdleSessionModule } from './services/idle-session/idle-session.module';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';

@NgModule({
  declarations: [ToggleButtonComponent
  ],
  providers: [
    TailwindService
  ],
  imports: [
    CommonModule,
    NavigationModule,
    HttpClientModule,
    MatIconModule,
    MatDialogModule,
    SparkConfirmationModule,
    //IdleSessionModule,
    SparkSplashScreenModule,
    DirectivesModule
  ],
  exports: [
    NavigationComponent, ToggleButtonComponent
  ]
})
export class SparkModule { }
