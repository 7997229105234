import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { NoAuthGuard } from '../core/auth/guards/noAuth.guard';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '../shared/shared.module';
import { MatStepperModule } from '@angular/material/stepper';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { VerifyUserComponent } from './login/verify-user/verify-user.component';
import { NoAccessModule } from './no-access/no-access.module';
import { NgIdleModule } from '@ng-idle/core';
import { ForgetPasswordComponent } from './login/forget-password/forget-password.component';
import { CountdownModule } from 'ngx-countdown';
import { RegisterComponent } from './register/register.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { AuthModule, getAuth, provideAuth } from '@angular/fire/auth';
import { initializeApp } from '@angular/fire/app';
import { environment } from 'src/environments/environment';
import { NgOtpInputModule } from 'ng-otp-input';
import { RoleVerifyModalComponent } from './login/role-verify-modal/role-verify-modal.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: LoginComponent,
      },
      {
        path: 'register/:roleId',
        component: RegisterComponent,
      },
      {
        path: 'forget',
        component: ForgetPasswordComponent,
      },
      {
        path: ':portal',
        component: LoginComponent,
      },
    ]
  },
]
@NgModule({
  declarations: [
    LoginComponent,
    VerifyUserComponent,
    ForgetPasswordComponent,
    RegisterComponent,
    RoleVerifyModalComponent
  ],
  imports: [
    CommonModule,
    CountdownModule,
    MatStepperModule,
    ImageCropperModule,
    MatCardModule,
    MatDatepickerModule,
    MatRadioModule,
    MatMenuModule,
    OverlayModule,
    NgIdleModule,
    AuthModule,
    NgOtpInputModule,
    provideAuth(() => {
      const auth = getAuth(initializeApp(environment.firebaseConfig));
      return auth;
    }),
    RouterModule.forChild(routes),
    SharedModule,
    MatListModule,
    MatCheckboxModule
  ]
})
export class AuthPagesModule { }
