import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { reject } from 'lodash';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CommonApiService {

  constructor(private _httpService: HttpService,
    private _http: HttpClient) { }

  getMaterialIcons() {
    return new Promise((resolve, reject) => {
      this._httpService.get('admin/gl/icons/getIcons').subscribe((data: any) => {
        resolve(data.data)
      }, (error) => {
        reject(error)
      })
    })
  }


  // Storage unit
  getStorageUnitsList() {
    return new Promise((resolve, reject) => {
      this._httpService.get('admin/server/storage/unit/getAllUnits?is_list=1').subscribe((data: any) => {

        resolve(data.data)
      }, (error) => {
        reject(error)
      })
    })
  }

  getPrefixes() {
    return new Promise((resolve, reject) => {
      this._httpService.get('public/attribute/user/prefix/getList').subscribe((data: any) => {
        resolve(data.data);
      },
        (error) => {

          reject(error)
        })
    })
  }

  verifyService(service) {
    return new Promise((resolve, reject) => {
      this._httpService.post('public/product/verifyService', { service }).subscribe((data: any) => {
        resolve(data.data);
      },
        (error) => {
          reject(error)
        })
    })
  }

  // get country codes
  getCountryCode() {
    return new Promise((resolve, reject) => {
      this._httpService.get('public/attribute/country/getCodes').subscribe((data: any) => {

        resolve(data.data)
      }, (error) => {
        reject(error)
      })
    })
  }



  getStatesByCountry(countryId) {
    return new Promise((resolve, reject) => {
      this._httpService.get('public/attribute/state/getAll?country_drc_key=' + countryId).subscribe((data: any) => {
        if (data.status_code == 200)
          resolve(data.data)
        else
          reject(data)
      }, (error) => {
        reject(error)
      })
    })
  }

  getCitiesByState(stateId) {
    return new Promise((resolve, reject) => {
      this._httpService.get('public/attribute/city/getAll?state_drc_key=' + stateId).subscribe((data: any) => {
        if (data.status_code == 200)
          resolve(data.data)
        else
          reject(data)
      }, (error) => {
        reject(error)
      })
    })
  }


}
