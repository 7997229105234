<div class="inset-0">
  <!-- Main -->
  <div class="flex flex-col flex-auto items-center justify-center h-full p-6 sm:p-10" *transloco="let t">
    <container-element [ngSwitch]="errorType">
      <img width="550" *ngSwitchCase="650" src="assets/illustration/server-down.png">
      <img width="550" *ngSwitchDefault src="assets/illustration/unknown-error.png">
    </container-element>
    <h3 class="mt-12 text-5xl sm:text-4xl text-center">{{errorText}}</h3>
    <ng-container *ngIf="errorType!=700">
      <button mat-stroked-button color="primary" class="mt-6 add-round-button"
        (click)="redirect()">{{t('action.try_again')}}</button>
    </ng-container>
  </div>
</div>