<ngx-ui-loader [loaderId]="'loader-01'"></ngx-ui-loader>
<div [class.loading-content]="isPageLoading" [class.lock-page]="isPageLoading">
  <ng-container *ngIf="isBadRequest">
    <div class="flex items-center justify-center h-full">
      <img class="bad-request" width="500" src="assets/400_error_bad_request.png" alt="">
    </div>
  </ng-container>
  <ng-container *ngIf="!isBadRequest">
    <ng-container *ngIf="!isVerifyComplete">
      <div class="middle-box mat-app-background shadow-lg" [loaderId]="'loader-01'" ngxUiLoaderBlurred [blur]="5"
        *transloco="let t">
        <img class="logo" [src]="config.scheme=='light'?config.logo1:config.logo1Dark" alt="">
        <ng-container *ngIf="!isRegisterComplete">
          <p class="text-center mt-3 mb-6 text-lg">{{this.roleId==2 ?
            t('auth.title.create_your_personal_account'):t('auth.title.create_your_business_account') }}!</p>
          <div class="form-group">
            <form [formGroup]="registerForm" (submit)="register()">
              <div class="flex sm:flex-col flex-row gap-x-4">
                <div class="flex-shrink w-20 sm:w-full">
                  <div class="control-box full-name-block prefix">
                    <mat-form-field class="" appearance="outline" floatLabel="never">
                      <mat-select formControlName="prefix_drc_key">
                        <mat-option *ngFor="let prefix of userPrefixes" [value]="prefix.prefix_drc_key">
                          {{prefix.name_prefix}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div fxFlex="1 0 0">
                  <div class="control-box full-name-block first-name">
                    <mat-form-field appearance="outline">
                      <mat-label>{{t('personal_details.title.first_name')}}</mat-label>
                      <input appTitleCase formControlName="first_name" matInput type="text">
                      <mat-error *ngIf="registerForm.get('first_name')?.errors?.required">
                        {{t('personal_details.messages.required.please_enter_first_name')}}
                      </mat-error>
                      <mat-error *ngIf="registerForm.get('first_name')?.errors?.serverType">
                        {{registerForm.get('first_name')?.errors?.serverType.message}}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex sm:flex-col flex-row gap-x-4">
                <div fxFlex="1 0 0" class="min-w-0">
                  <div class="control-box full-name-block last-name">
                    <mat-form-field appearance="outline">
                      <mat-label>{{t('personal_details.title.last_name')}}</mat-label>
                      <input appTitleCase formControlName="last_name" matInput type="text">
                      <mat-error *ngIf="registerForm.get('last_name')?.errors?.required">
                        {{t('personal_details.messages.required.please_enter_last_name')}}
                      </mat-error>
                      <mat-error *ngIf="registerForm.get('last_name')?.errors?.serverType">
                        {{registerForm.get('last_name')?.errors?.serverType.message}}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="flex sm:flex-col flex-row gap-x-4">
                <mat-form-field appearance="outline" class="country-select flex-initial w-40 sm:w-full"
                  floatLabel="never">
                  <mat-select [(ngModel)]="selectedCountryCode" formControlName="country_drc_key">
                    <mat-option *ngFor="let code of countryCodes" [value]="code.country_drc_key"
                      (change)="changeCountry(code)">+
                      {{code.phonecode}} {{code.iso3}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="" appearance="outline" class="mobile-box">
                  <mat-label>{{t('personal_details.title.mobile')}}</mat-label>
                  <input appOnlyNumbers formControlName="mobile_no" matInput type="text" maxlength="10">
                  <mat-error *ngIf="registerForm.get('mobile_no')?.errors?.required">
                    {{t('personal_details.messages.required.please_enter_your_mobile')}}
                  </mat-error>
                  <mat-error *ngIf="registerForm.get('mobile_no')?.errors?.serverType">
                    {{registerForm.get('mobile_no')?.errors?.serverType.message}}
                  </mat-error>
                  <mat-error
                    *ngIf="registerForm.get('mobile_no')?.errors?.minlength && !registerForm.get('mobile_no')?.errors?.pattern">
                    {{t('personal_details.messages.required.please_enter_valid_mobile')}}
                  </mat-error>
                  <mat-error *ngIf="registerForm.get('mobile_no')?.errors?.pattern">
                    {{t('personal_details.messages.required.please_enter_english_numerics')}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="flex flex-col gap-x-4">
                <div class="control-box" *ngIf="roleId==1">
                  <mat-form-field appearance="outline">
                    <mat-label>{{t('personal_details.title.email')}}</mat-label>
                    <input (blur)="getUsername()" formControlName="email_id" matInput type="email">
                    <mat-error *ngIf="registerForm.get('email_id')?.errors?.required">
                      {{t('personal_details.messages.required.please_enter_your_email')}}
                    </mat-error>
                    <mat-error *ngIf="registerForm.get('email_id')?.errors?.serverType">
                      {{registerForm.get('email_id')?.errors?.serverType.message}}
                    </mat-error>
                    <mat-error *ngIf="registerForm.get('email_id')?.errors?.pattern">
                      {{t('personal_details.messages.required.please_enter_valid_email')}}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>


              <mat-checkbox formControlName="i_agree">
                <span class="whitespace-normal">{{t('i_agree_to_the')}} <a target="_blank"
                    href="{{ orgUrl }}/policy/terms-and-conditions">{{t('terms_and_conditions')}}</a></span>
              </mat-checkbox>
              <mat-error class="mt-1 text-xs"
                *ngIf="registerForm.get('i_agree')?.touched &&registerForm.get('i_agree')?.errors?.required">
                {{t('auth.messages.required.please_accept_terms_condition')}}
              </mat-error>
              <div style="clear: both;" class="mb-4"></div>
              <div class="control-box ">
                <button mat-button class="w-full success-button add-round-button" [disabled]="isRegisterLoading">
                  <span *ngIf="!isRegisterLoading">
                    <mat-icon class="mr-2 icon-size-5">login</mat-icon>{{t('auth.title.register')}}
                  </span>
                  <div *ngIf="isRegisterLoading" style="padding: 8px;">
                    <mat-spinner style="margin: auto;" [diameter]="20"></mat-spinner>
                  </div>
                </button>
              </div>
              <div class="text-center">
                <a [routerLink]="['/']" queryParamsHandling="merge" mat-button
                  class="text-primary text-center mt-4 font-medium add-round-button m-auto">
                  <span>{{t('auth.title.back_to_login')}}</span>
                </a>
              </div>
              <!-- <ng-container *ngIf="this.roleId==2">
                            <div class="w-full text-center mt-4 mb-2">
                                <span>
                                    or
                                </span>
                            </div>
                            <div class="control-box ">
                                <button mat-button class="w-full add-round-button" type="button"
                                    (click)="signUpWithGoogle()">
                                    <div class="google-icon-wrapper flex gap-2 m-auto justify-center">
                                        <img class="google-icon" src="assets/g-logo.svg" />
                                        <p class="btn-text"> Sign up with google</p>
                                    </div>
                                </button>
                            </div>
                        </ng-container> -->
            </form>
          </div>
        </ng-container>

        <ng-container *ngIf="isRegisterComplete">
          <p class="text-center mb-6 text-xl">{{ t('auth.title.just_one_step_ahead')}}!</p>
          <div class="form-group">
            <p class="text-base">{{ t('auth.title.enter_otp')}}</p>
            <p class="sent-mail-msg text-sm secondary-text">
              {{t('auth.title.otp_sent_to_mobile',{mobile:registerForm.get('mobile_no')?.value})}}
            </p>
            <div class="otp-box mt-3">
              <ng-otp-input [formCtrl]="otpCtrl" [config]="{length:6}"></ng-otp-input>
              <div class="mt-3 secondary-text">
                <span *ngIf="!(isOTPExpires || isResendError)">
                  {{t('auth.title.otp_expires_in_time')}}
                  <countdown #cd [config]="{leftTime:90,demand:true,format:'mm:ss'}" (event)="handleEvent($event)">
                  </countdown>
                </span>
                <span class="mt-2 text-blue-500" *ngIf="isOTPExpires || isResendError">
                  <a (click)="resendOTP()" class="cursor-pointer">{{t('auth.title.resend_otp')}}</a>
                </span>
              </div>
            </div>
            <button mat-button class="w-full mt-6 success-button add-round-button"
              [disabled]="otpCtrl.invalid || isVerifyingOTP || isSendingOTP" type="button" (click)="verifyOTP()">
              <span *ngIf="!(isVerifyingOTP || isSendingOTP)"> {{t('auth.title.verify_now')}}
              </span>
              <div *ngIf="isVerifyingOTP || isSendingOTP" style="padding: 8px;">
                <mat-spinner style="margin: auto;" [diameter]="20"></mat-spinner>
              </div>
            </button>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="isVerifyComplete">
      <div
        class="middle-box flex flex-col justify-center items-center h-full shadow-none bg-transparent text-center mat-app-background"
        *transloco="let t">

        <h3 class="login-title">
          <div class="trigger" [class.drawn]="verifySuccessStatus"></div>
          <svg version="1.1" id="tick" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 37 37" style="enable-background:new 0 0 37 37;" xml:space="preserve">
            <path class="circ path"
              style="fill:none;stroke:#000;stroke-width:3;stroke-linejoin:round;stroke-miterlimit:10;"
              d="
	M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z" />
            <polyline class="tick path"
              style="fill:none;stroke:#000;stroke-width:3;stroke-linejoin:round;stroke-miterlimit:10;" points="
	11.6,20 15.9,24.2 26.4,13.8 " />
          </svg>
          <p class="message secondary-text mt-1">
            {{t('auth.messages.success.your_mobile_verified_successfully')}}
          </p>
          <span class="text-2xl"
            style="vertical-align: middle;margin-left: 8px;">{{t('auth.messages.success.thank_you_for_registeration')}}</span>
        </h3>

        <p class="message mt-4">
          {{t('auth.title.logging_in_sec',{ sec:timer })}}
        </p>
      </div>
    </ng-container>
  </ng-container>
</div>