/**
 * Copyrights Reserved 2023 SPARK Technologies!
 *
 * @author SPARK Technologies
 * @category Login
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UtilityService } from 'src/app/core/common-service/utility.service';

@Component({
  selector: 'app-role-verify-modal',
  templateUrl: './role-verify-modal.component.html',
  styleUrls: ['./role-verify-modal.component.scss']
})
export class RoleVerifyModalComponent implements OnInit {
  foodMenuTypes: any[] = []

  isLoading

  roleCtrl: FormControl = new FormControl(2, Validators.required)

  constructor(
    public dialogRef: MatDialogRef<RoleVerifyModalComponent>,
    private _utility: UtilityService,
  ) { }

  ngOnInit(): void {
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Operations
  // -----------------------------------------------------------------------------------------------------

  register() {
    this.dialogRef.close({ status: 'approve', role: this.roleCtrl.value })
  }
}
