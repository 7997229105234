import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from './click-outside.directive';
import { DragDropDirectiveDirective } from './drag-drop-directive.directive';
import { OnlyNumbersDirective } from './only-numbers.directive';
import { TitleCaseDirective } from './title-case.directive';
import { LowercaseDirective } from './input-lowercase.directive';



@NgModule({
  declarations: [
    ClickOutsideDirective,
    DragDropDirectiveDirective,
    OnlyNumbersDirective,
    TitleCaseDirective,
    LowercaseDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ClickOutsideDirective,
    DragDropDirectiveDirective,
    OnlyNumbersDirective,
    TitleCaseDirective,
    LowercaseDirective
  ]
})
export class DirectivesModule { }
