/**
 * Copyrights Reserved 2023 SPARK Technologies!
 *
 * @author SPARK Technologies
 * @category FCM
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { Injectable, Optional } from '@angular/core';
import { Messaging, onMessage, getToken } from '@angular/fire/messaging';
import { BehaviorSubject, EMPTY, from, Observable, pipe } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../common-service/utility.service';
@Injectable({
  providedIn: 'root'
})

export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  message$: Observable<any> = EMPTY;
  token;

  get token$(): Observable<any> {
    return this.token;
  }
  constructor(@Optional() messaging: Messaging,
    private _utility: UtilityService) {
    if (messaging && Notification.permission) {

      navigator.serviceWorker.register('firebase-messaging-sw.js', { type: 'module', scope: '__' }).
        then(serviceWorkerRegistration =>
          getToken(messaging, {
            serviceWorkerRegistration
          }).then((token) => {
            this.token = token
            console.log(token);

          }).catch((error) => {
            _utility.showWarning("Unable to get permission to notify!")
            console.error('Unable to get permission to notify.', error);
          })
        )

      // navigator.serviceWorker.onmessage = (event) => {
      //   if (event.data.data) {
      //     //console.log(event.data.data)
      //     this.currentMessage.next(event.data.data);
      //   }

      // };

      onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
      });

    }


  }


}
