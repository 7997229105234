/**
 * Copyrights Reserved 2023 SPARK Technologies!
 *
 * @author SPARK Technologies
 * @category Toggle button
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SparkConfigService } from 'src/@spark/services/config/config.service';

@Component({
  selector: 'spark-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent {
  @Output() changed = new EventEmitter<Event>();
  isDark = false
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  config: any;
  constructor(
    private _sparkConfigService: SparkConfigService,
  ) {

  }
  ngOnInit() {
    this._sparkConfigService.config$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((config: any) => {
        this.config = config;
        this.isDark = config.scheme == 'light' ? false : true
      })
  }

  switch(event) {
    this.isDark = event.target.checked
    let scheme = this.isDark ? 'dark' : 'light'
    this._sparkConfigService.config = { scheme };
  }
}
