/**
 * Copyrights Reserved 2023 SPARK Technologies!
 *
 * @author SPARK Technologies
 * @category Service
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { merge } from 'lodash-es';
import { SparkConfirmationConfig } from './confirmation.types';
import { SparkConfirmationDialogComponent } from './dialog/dialog.component';

@Injectable()
export class SparkConfirmationService {
  private _defaultConfig: SparkConfirmationConfig = {
    title: 'Confirm action',
    message: 'Are you sure you want to confirm this action?',
    icon: {
      show: true,
      name: 'heroicons_outline:exclamation',
      color: 'warn'
    },
    actions: {
      confirm: {
        show: true,
        label: 'Confirm',
        color: 'warn'
      },
      cancel: {
        show: true,
        label: 'Cancel'
      }
    },
    dismissible: false
  };

  /**
   * Constructor
   */
  constructor(
    private _matDialog: MatDialog
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  open(config: SparkConfirmationConfig = {}): MatDialogRef<SparkConfirmationDialogComponent> {
    // Merge the user config with the default config
    const userConfig = merge({}, this._defaultConfig, config);

    // Open the dialog
    return this._matDialog.open(SparkConfirmationDialogComponent, {
      autoFocus: false,
      disableClose: !userConfig.dismissible,
      data: userConfig
    });
  }

  close(config: SparkConfirmationConfig = {}) {
    // Merge the user config with the default config
    const userConfig = merge({}, this._defaultConfig, config);

    // Open the dialog
    return this._matDialog.closeAll();
  }
}
