<div class="middle-box mat-app-background rounded-3xl px-6 py-3 shadow-lg" *transloco="let t">
  <img class="logo" [src]="config.scheme=='light'?config.logo1:config.logo1Dark" alt="">
  <p class="text-center mb-4 text-lg mt-3">{{t('auth.title.recover_your_spark_account')}}</p>
  <div class="verify-box" *ngIf="!isVerified">
    <div class="form-group">
      <form [formGroup]="forgetPasswordForm" (submit)="verifyUser()">
        <div class="control-box">
          <mat-form-field class="" appearance="outline" class="mobile-box">
            <mat-label>{{t('personal_details.title.mobile')}}</mat-label>
            <input appOnlyNumbers formControlName="mobile_no" matInput type="text" maxlength="10">
            <mat-error *ngIf="forgetPasswordForm.get('mobile_no')?.errors?.required">
              {{t('personal_details.messages.required.please_enter_your_mobile')}}
            </mat-error>
            <mat-error *ngIf="forgetPasswordForm.get('mobile_no')?.errors?.serverType">
              {{forgetPasswordForm.get('mobile_no')?.errors?.serverType.message}}
            </mat-error>
            <mat-error *ngIf="forgetPasswordForm.get('mobile_no')?.errors?.minlength">
              {{t('personal_details.messages.required.please_enter_valid_mobile')}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="control-box">
          <button mat-button class="w-full success-button add-round-button"
            [disabled]="forgetPasswordForm.invalid || isLoading">
            <span *ngIf="!isLoading">{{t('auth.actions.verify')}}</span>
            <div *ngIf="isLoading" style="padding: 8px;">
              <mat-spinner style="margin: auto;" [diameter]="20"></mat-spinner>
            </div>
          </button>
        </div>
        <p class="secondary-text text-sm mt-4 float-right">
          <a mat-button color="primary" class="add-round-button" routerLink="/"
            queryParamsHandling="merge">{{t('auth.actions.back_to_login')}}</a>
        </p>
        <div style="clear: both;"></div>
      </form>
    </div>
  </div>
  <div class="otp-box" *ngIf="isVerified && !isCodeVerified">
    <div class="form-group">
      <p class="text-base">{{ t('auth.title.enter_otp')}}</p>
      <p class="text-sm secondary-text">
        {{t('auth.title.otp_sent_to_mobile',{mobile:forgetPasswordForm.get('mobile_no')?.value})}}
      </p>
      <div class="mt-3">
        <ng-otp-input [formCtrl]="otpCtrl" [config]="{length:6}"></ng-otp-input>
        <div class="mt-3 secondary-text">
          <span *ngIf="!(isOTPExpires || isResendError)">
            {{t('auth.title.otp_expires_in_time')}}
            <countdown #cd [config]="{leftTime:90,demand:true}" (event)="handleEvent($event)"></countdown>
          </span>
          <span class="mt-2 text-blue-500" *ngIf="isOTPExpires || isResendError">
            <a (click)="resendOTP()" class="cursor-pointer">{{t('auth.title.resend_otp')}}</a>
          </span>
        </div>
      </div>
      <button mat-button class="w-full mt-6 success-button add-round-button" [disabled]="otpCtrl.invalid || isLoading"
        type="button" (click)="verifyCode()">
        <span *ngIf="!(isLoading)"> {{t('auth.title.verify_now')}}
        </span>
        <div *ngIf="isLoading" style="padding: 8px;">
          <mat-spinner style="margin: auto;" [diameter]="20"></mat-spinner>
        </div>
      </button>
    </div>
  </div>
  <div class="new-password-box" *ngIf="isCodeVerified">
    <div class="form-group">
      <form [formGroup]="changePasswordForm" (submit)="setNewPassword()">
        <div class="control-box">
          <mat-form-field appearance="outline">
            <mat-label>{{t('auth.title.password')}}</mat-label>
            <input formControlName="password" #emailId matInput [type]="hide ? 'password' : 'text'" cdkOverlayOrigin
              #trigger="cdkOverlayOrigin" (input)="checkPassword($event)" (focus)="checkPassword($event)"
              (blur)="isOpen=false">
            <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="changePasswordForm.get('password')?.errors?.required">
              {{t('auth.messages.required.please_enter_your_password')}}
            </mat-error>
            <mat-error *ngIf="changePasswordForm.get('password')?.errors?.pattern">
              {{t('auth.messages.required.password_does_not_match_with_conditions')}}
            </mat-error>
          </mat-form-field>
          <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen">
            <ul class="validation-list">
              <li *ngFor="let validation of validationList" [class.verified]="validation.status"
                [class.unverified]="!validation.status">
                <mat-icon>check_circle</mat-icon> {{validation.name}}
              </li>
            </ul>
          </ng-template>
        </div>
        <div class="control-box">
          <mat-form-field appearance="outline">
            <mat-label>{{t('auth.title.confirm_password')}}</mat-label>
            <input formControlName="password_confirmation" #emailId matInput type="password">
            <mat-error *ngIf="changePasswordForm.get('password_confirmation')?.errors?.required">
              {{t('auth.messages.required.please_enter_password_again')}}
            </mat-error>
            <mat-error *ngIf="changePasswordForm.get('password_confirmation')?.errors?.mustMatch">
              {{t('auth.messages.required.passwords_do_not_match')}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="control-box">
          <button mat-button class="w-full success-button add-round-button"
            [disabled]="changePasswordForm.invalid || isLoading">
            <span *ngIf="!isLoading">{{t('auth.actions.change_password')}}</span>
            <div *ngIf="isLoading" style="padding: 8px;">
              <mat-spinner style="margin: auto;" [diameter]="20"></mat-spinner>
            </div>
          </button>
        </div>
        <p class="secondary-text text-sm mt-4 float-right">
          <a routerLink="/" queryParamsHandling="merge">{{t('auth.actions.back_to_login')}}</a>
        </p>
        <div style="clear: both;"></div>
      </form>
    </div>
  </div>
</div>