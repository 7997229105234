/**
 * Copyrights Reserved 2023 SPARK Technologies!
 *
 * @author SPARK Technologies
 * @category Login
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgOtpInputComponent } from 'ng-otp-input';
import { CountdownComponent } from 'ngx-countdown';
import { Subject, takeUntil } from 'rxjs';
import { SparkConfigService } from 'src/@spark/services/config/config.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UtilityService } from 'src/app/core/common-service/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  forgetPasswordForm: FormGroup;
  changePasswordForm: FormGroup;
  otpCtrl: FormControl = new FormControl('', [Validators.required, Validators.minLength(6)])

  userKey;
  userLoginKey;
  otpKey;
  config: any;

  isVerified = false;
  isCodeVerified = false;
  isLoading = false;
  timerStatus = false;
  isOTPExpires = false
  isResendError;
  isResent = false;
  isOpen = false;
  hide = true;

  validationList: any[] = [
    { 'name': "At least one digit", "status": false },
    { 'name': "min 8 character", "status": false },
    { 'name': "At least one lower case", "status": false },
    { 'name': "At least one upper case", "status": false },
    { 'name': "At least one special character", "status": false },
  ];

  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  @ViewChild(NgOtpInputComponent, { static: false }) ngOtpInput: NgOtpInputComponent;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _fb: FormBuilder,
    private _utility: UtilityService,
    private _authService: AuthService,
    private _cdr: ChangeDetectorRef,
    private _configService: SparkConfigService,
    private _router: Router) {
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.forgetPasswordForm = this._fb.group({
      'mobile_no': ['', [Validators.required, Validators.minLength(10)]],
    })

    this.changePasswordForm = this._fb.group({
      'password': ['', Validators.required],
      'password_confirmation': ['', Validators.required],
    }, {
      validator: this._utility.MustMatch('password', 'password_confirmation')
    })

    this._configService.config$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((config: any) => {
        this.config = config;
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Events
  // -----------------------------------------------------------------------------------------------------

  handleEvent(event) {
    if (event.action == 'start') {
      this.timerStatus = true;
    }
    else if (event.action == 'done') {
      this.isOTPExpires = true
    }
  }

  // check password conditions
  checkPassword(event) {
    if (event.target.value.length > 0) {
      this.isOpen = true;
    }
    // check for digits
    this.validationList[0].status = /\d/.test(event.target.value);
    // check for min 8 characters
    this.validationList[1].status = event.target.value.length >= 8;
    // check at least one lower case
    this.validationList[2].status = /[a-z]/.test(event.target.value);
    // check at least one upper case
    this.validationList[3].status = /[A-Z]/.test(event.target.value);
    // check at least one special character
    this.validationList[4].status = /[#?!@$%^&*-]/.test(event.target.value);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Operations
  // -----------------------------------------------------------------------------------------------------

  verifyUser() {
    this.isLoading = true;
    this._authService.verifyUser(this.forgetPasswordForm.get('mobile_no')?.value).then((data: any) => {
      this.isVerified = true;
      this._cdr.detectChanges()
      this.userKey = data.user_details_key;
      this.otpKey = data.otp_details.otp_key;
      this.userLoginKey = data.user_login.user_login_key;
      this.countdown.begin();
      if (!environment.production)
        this.ngOtpInput.setValue(data.otp_details.verification_code)
    }).catch((error) => {
      this.isVerified = false;
    }).finally(() => {
      this.isLoading = false;
    })
  }

  verifyCode() {
    this.isLoading = true;
    let data = {
      'verification_code': this.otpCtrl.value,
      'mobile_no': this.forgetPasswordForm.get('mobile_no')?.value,
      'user_details_key': this.userKey,
      'otp_key': this.otpKey,
      'otp_type': 1,
    }
    this._authService.verifyOTP(data).then((data: any) => {
      if (data.data) {
        this.isCodeVerified = true;
        this.userKey = ''
        if (!this.timerStatus)
          this.countdown.stop()
      } else {
        this._utility.showFailure(data.message)
        this.isCodeVerified = false
      }
    }).catch((data) => {
      this.isCodeVerified = false;
      this._utility.showFailure(data.message)
    }).finally(() => {
      this.isLoading = false;
    })

  }

  setNewPassword() {
    this.isLoading = true;
    let data = {
      'verification_status': 1,
      'user_login_key': this.userLoginKey,
      'otp_key': this.otpKey,
      ...this.changePasswordForm.value
    }
    this._authService.setNewPassword(data)
      .then((data: any) => {
        this.otpKey = ''
        this._utility.showSuccess(data.message)
        this._router.navigateByUrl('/')
      }).catch((data) => {
        this._utility.showFailure(data.message)
      }).finally(() => {
        this.isLoading = false;
      })
  }

  resendOTP() {
    this.isLoading = true;
    this.ngOtpInput.setValue('')
    let data = {
      'user_details_key': this.userKey,
      'otp_type': 1,
    }
    this._authService.resendRegisterOTP(data).then((data: any) => {
      if (data.data) {
        this.timerStatus = true;
        this.isResendError = false
        this.otpKey = data.data.otp_key
        this.countdown.begin();
        if (!environment.production)
          this.ngOtpInput.setValue(data.data.verification_code)
      } else {
        this._utility.showFailure(data.message)
        this.isResendError = true
      }
    }).finally(() => {
      this.isLoading = false;
    }).catch((error) => {
      this.isResendError = true
    })
  }

}
