import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../../common-service/utility.service';
@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanActivate, CanActivateChild, CanLoad {
  /**
   * Constructor
   */
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _utility: UtilityService,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this._check(route.params, route.queryParams);
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._check(childRoute.params, childRoute.queryParams);
  }

  /**
   * Can load
   *
   * @param route
   * @param segments
   */
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this._check('', '');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @private
   */
  private _check(routeParam, parameter): Observable<boolean> {
    // Check the authentication status
    // let roleId = routeParam['roleId'] == this._utility.encrypt("personal") ? 2 : routeParam['roleId'] == this._utility.encrypt("business") ? 1 : null;
    // if (parameter.role) {
    //     roleId = parameter.role
    // }
    // if (routeParam['roleId']) {
    //     roleId = routeParam['roleId']
    // }

    // let key = roleId == 2 ? CryptoJS.SHA256("personalSecret") : CryptoJS.SHA256("adminSecret");
    // if (this.cookieService.get(key)) {
    //     window.location.href = environment.myaccountDomain
    //     return of(false);
    // }
    // Allow the access
    return of(true);

  }
}
