/**
 * Copyrights Reserved 2023 SPARK Technologies!
 *
 * @author SPARK Technologies
 * @category Navigation
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SparkNavigationService } from './navigation.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  @Input() navigation: any[] = [];
  top = 0;
  activeId = -1;
  hoverId = -1;
  menueItemHeight = 65;
  urlTree;
  urlSegments = [];
  pathFound = false;
  isSidebarFold;

  onCollapsableItemExpanded: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private router: Router,
    private _navigationService: SparkNavigationService) {
    this.router.events
      .subscribe(
        (event) => {
          if (event instanceof NavigationEnd) {
            this.setActiveMenue();
          }
        });
  }


  ngOnInit() {
    this._navigationService.onSidebarFold.pipe(takeUntil(this._unsubscribeAll)).subscribe((data) => {
      this.isSidebarFold = data
    })
  }

  setActiveMenue() {
    let parentMenuIndex = 0; let top = 0;
    let expandedMenu;
    let expandedIndex = 0;
    let urlTree = this.router.parseUrl(this.router.url);
    let urlSegments = urlTree.root.children['primary']?.segments
    let pathFound = false
    this.navigation.forEach((parentElement, parentIndex) => {
      let urlWithoutParams = urlSegments?.slice(0, 1).map(it => it.path).join('/');

      if (urlWithoutParams === parentElement?.link) {
        parentMenuIndex = parentIndex;
        pathFound = true;
      }
      if (parentElement.children_menu) {
        urlWithoutParams = urlSegments?.slice(0, 2).map(it => it.path).join('/');
        parentElement.children_menu.forEach((element, i) => {
          if (urlWithoutParams === element.link) {
            parentMenuIndex = parentIndex;
            pathFound = true
          }
        });
      }
      if (parentIndex <= parentMenuIndex && expandedMenu && parentIndex != expandedIndex && !this.isSidebarFold) {
        top += (expandedMenu?.children_menu?.length * this.menueItemHeight + (expandedMenu?.children_menu?.length * 8) + 8);
      }

      if (parentElement.expanded) {
        expandedMenu = parentElement;
        expandedIndex = parentIndex
      }
    })

    if (pathFound) {
      this.top = (parentMenuIndex * this.menueItemHeight) + top;
      this.activeId = parentMenuIndex;
      this.hoverId = parentMenuIndex;
    }
    else {
      this.top = -65
      this.activeId = -1;
      this.hoverId = -1;
      //this.hoverId = null
    }
  }

  ngAfterViewInit() {
    this.setActiveMenue()
  }

  menuHoverIn(i) {
    this.hoverId = i
    this.top = (i * this.menueItemHeight);
    this.navigation.forEach((element, index) => {
      if ((index < i) && element.expanded) {
        this.top += (element.children_menu.length * this.menueItemHeight + (element?.children_menu?.length * 8) + 8);
      }
    });
  }

  menuHoverOut() {
    this.setActiveMenue()
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(0);
    this._unsubscribeAll.complete();
  }
}
