/**
 * Copyrights Reserved 2023 SPARK Technologies!
 *
 * @author SPARK Technologies
 * @category Navigation
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, ElementRef, Input, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigationComponent } from '../navigation.component';
import { SparkNavigationService } from '../navigation.service';

@Component({
  selector: 'app-navigation-collapsable',
  templateUrl: './collapsable.component.html',
  styleUrls: ['./collapsable.component.scss'],

})
export class CollapsableComponent implements OnInit {
  @Input() item
  @Input() index
  isSidebarFold;

  @ViewChild('parent', { static: false }) parent: any;
  @ViewChild('child') child;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _navigationService: SparkNavigationService,
    private _navigationComponent: NavigationComponent,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef) {

  }

  ngOnInit(): void {
    this._navigationComponent.onCollapsableItemExpanded.pipe(takeUntil(this._unsubscribeAll)).subscribe((expandedItem) => {

      if (expandedItem.id != this.item.id) {
        this.item.expanded = false
      }

    })
    this._navigationService.onSidebarFold.pipe(takeUntil(this._unsubscribeAll)).subscribe((data) => {
      this.isSidebarFold = data
      if (data)
        this.item.expanded = false
      this.closeDropDown()
    })
  }

  toggleDropdown(item) {
    if (!this.isSidebarFold) {
      item.expanded = !this.item.expanded
      //this._changeDetectorRef.markForCheck()
      this._navigationComponent.onCollapsableItemExpanded.next(item)
    }

  }

  closeDropDown() {
    //this.selectedId = 0;
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }

  }
  overlayRef: any;
  showOverlay() {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create(
        new OverlayConfig({
          panelClass: 'submenu-overlay',
          width: 'auto',
          positionStrategy: this.overlay.position().flexibleConnectedTo(this.parent).withPositions([{
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'top',
            offsetX: 65,
            offsetY: 0
          }])
        })
      );
      this.overlayRef.attach(new TemplatePortal(this.child, this.viewContainerRef))
    }
    else {
      //this.closeDropDown()
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(0);
    this._unsubscribeAll.complete();
  }
}
