<ng-container *ngIf="isBadRequest">
  <div class="flex items-center justify-center h-full">
    <img class="bad-request" width="700" *ngIf="(_configService.config$ | async).scheme=='light'"
      src="assets/400_error_bad_request.png" alt="">
    <img class="bad-request" width="700" *ngIf="(_configService.config$ | async).scheme=='dark'"
      src="assets/400_error_bad_request_dark.png" alt="">
  </div>
</ng-container>
<ng-container *ngIf="!isBadRequest">
  <div *transloco="let t">
    <!-- Loader -->
    <ngx-ui-loader [loaderId]="'loader-01'"></ngx-ui-loader>

    <ng-container *ngIf="!verificationStep">
      <div class="login-box m-auto rounded-3xl shadow-lg overflow-hidden" *ngIf="!isPickup" [loaderId]="'loader-01'"
        ngxUiLoaderBlurred [blur]="5">
        <div class="inner flex-1 px-8 py-6 sm:px-4 box-border">
          <!-- logo -->
          <img class="logo" [src]="config.scheme=='light'?config.logo1:config.logo1Dark" alt="">
          <p class="text-center mt-3 mb-6 text-lg sm:text-base">
            <span>{{t('auth.title.login_to_product',{product:portal?product?.product_name:'SPARK'})}}</span>
          </p>
          <!-- Login form -->
          <div class="form-group">
            <form [formGroup]="loginForm">
              <div class="control-box">
                <mat-form-field appearance="outline">
                  <mat-label>{{t('auth.title.registered_mobile_no')}}</mat-label>
                  <input formControlName="username" matInput type="text" appOnlyNumbers maxlength="10">
                  <mat-error *ngIf="loginForm.get('username')?.touched && loginForm.get('username')?.errors?.required">
                    {{t('personal_details.messages.required.please_enter_your_mobile')}}
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Login -->
              <div class="control-box ">
                <button mat-button class="w-full success-button add-round-button"
                  [disabled]="loginForm.invalid || isLoading" type="button" (click)="login()">
                  <span *ngIf="!isLoading">
                    {{t('auth.title.continue')}}
                  </span>
                  <div *ngIf="isLoading" style="padding: 8px;">
                    <mat-spinner style="margin: auto;" [diameter]="20"></mat-spinner>
                  </div>
                </button>
              </div>
              <!-- Create account -->
              <div class="mt-3">
                <p class="text-sm text-center secondary-text">{{t('auth.title.don_t_have_spark_account')}}
                  <a [matMenuTriggerFor]="menu" class="font-medium">{{t('auth.title.create_one')}}</a>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item queryParamsHandling="merge" [routerLink]="['/register/'+businessKey]">
                      <span class="text-base">{{t('business_account')}}</span> <span class="text-xs secondary-text">
                        ({{t('auth.title.for_business_owners')}})</span>
                      <p class="text-xs secondary-text mt-1 gap-1 flex items-center">
                        {{t('auth.title.to_provide_services_to_your_customers')}}
                      </p>
                    </button>
                    <button mat-menu-item queryParamsHandling="merge" [routerLink]="['/register/'+personalKey]">
                      <span class="text-base"> {{t('personal_account')}}</span> <span class="text-xs secondary-text">
                        ({{t('auth.title.for_normal_users')}})</span>
                      <p class="text-xs secondary-text mt-1 gap-1 flex items-center">
                        {{t('auth.title.to_use_services_provided_by_business')}}
                      </p>
                    </button>
                  </mat-menu>
                </p>
              </div>
              <div class="w-full text-center mt-6 mb-6">
                <span class="block text-center vertical-text-line text-secondary text-sm relative">{{t('or')}}</span>
              </div>
              <div class="control-box mb-4">
                <button mat-button class="w-full add-round-button" type="button" (click)="loginWithGoogle()">
                  <div class="google-icon-wrapper flex gap-2 m-auto justify-center">
                    <img class="google-icon" src="assets/g-logo.svg" />
                    <p class="btn-text"> {{t('auth.title.sign_in_with_google')}}</p>
                  </div>
                </button>
              </div>

              <!-- Forget password
              <a mat-button color="primary" class="forget-text add-round-button" routerLink="/forget"
                queryParamsHandling="merge">{{t('auth.title.forget_password')}}</a> -->

              <div style="clear: both;"></div>

            </form>
          </div>
        </div>
      </div>

      <!-- Pickup account -->
      <div *ngIf="isPickup" class="pickup-box shadow-lg sm:w-11/12 rounded-2xl">
        <img class="logo" [src]="config.scheme=='light'?config.logo1:config.logo1Dark" alt="">
        <p class="text-center text-lg mb-4 mt-3">{{t('auth.title.login_to_continue_to')}}
          {{portal?product?.product_name:'SPARK'}}
        </p>
        <h3 class="font-medium text-lg mb-2">{{t('auth.title.pick_an_account')}}</h3>
        <!-- Account list -->
        <mat-list>
          <mat-list-item class="border-solid border-2 rounded-md border-accent mb-3" (click)="goToDestination(i,acc.expires_at)"
            *ngFor="let acc of accountList; let i=index" [hidden]="this.roleId && acc.role != this.roleId">
            <div class="flex w-full justify-between cursor-pointer py-3 items-center">
              <span class="flex items-center">
                <div class="mr-3 text-accent-600">
                  <mat-icon class="material-icons-outlined icon-size-10 overlay-menu-icon" *ngIf="acc.role==2">
                    account_box</mat-icon>
                  <mat-icon class="material-icons-outlined icon-size-10 overlay-menu-icon" *ngIf="acc.role==1">
                    assignment_ind</mat-icon>
                </div>
                <div>
                  <div class="flex gap-x-1 items-center sm:items-start sm:flex-col">
                    <span>{{acc.full_name}}</span>
                    <span class="text-sm"> ({{acc.role==1?'Business':"Personal"}})</span>
                  </div>
                  <p class="text-sm secondary-text">{{acc.email_id ?? acc.username}}</p>
                  <p *ngIf="!(acc.expires_at | dateCompare)" class="text-sm text-red-500 mt-1">
                    {{ 'Session expired' }}
                  </p>
                </div>
              </span>
              <span class="text-sm cursor-pointer">
                <mat-icon class="text-red-400 icon-size-6.5 material-icons-outlined"
                  (click)="$event.stopPropagation();removeAccount(i)">delete_outline</mat-icon>
              </span>
            </div>
          </mat-list-item>
          <!-- Add new -->
          <ng-container *ngIf="accountList.length<10">
            <a mat-button color="primary" class="cursor-pointer add-round-button" (click)="isPickup=false">
              <mat-icon>add</mat-icon>
              {{t('auth.title.add_another_account')}}</a>
          </ng-container>
        </mat-list>
      </div>
    </ng-container>

    <ng-container *ngIf="verificationStep">
      <div class="verify-box w-fit m-auto rounded-3xl shadow-lg overflow-hidden">
        <div class="inner flex-1 px-8 py-6 sm:px-4 box-border">
          <p class="text-center mb-6 text-xl">{{ t('auth.title.just_one_step_ahead')}}!</p>
          <div class="form-group">
            <p class="text-base">{{ t('auth.title.enter_otp')}}</p>
            <p class="sent-mail-msg text-sm secondary-text">
              {{t('auth.title.otp_sent_to_mobile',{mobile:this.loginForm.get('username')?.value})}}
            </p>
            <div class="otp-box mt-3">
              <ng-otp-input [formCtrl]="otpCtrl" [config]="{length:6}"></ng-otp-input>
              <div class="mt-3 secondary-text">
                <span *ngIf="!(isOTPExpires || isResendError)">
                  {{t('auth.title.otp_expires_in_time')}}
                  <countdown #cd [config]="{leftTime:90,demand:true,format:'mm:ss'}" (event)="handleEvent($event)">
                  </countdown>
                </span>
                <span class="mt-2 text-blue-500" *ngIf="isOTPExpires || isResendError">
                  <a (click)="resendOTP()" class="cursor-pointer">{{t('auth.title.resend_otp')}}</a>
                </span>
              </div>
            </div>
            <button mat-button class="w-full mt-6 success-button add-round-button"
              [disabled]="otpCtrl.invalid || isVerifyingOTP || isLogging" type="button" (click)="verifyOTP()">
              <span *ngIf="!(isVerifyingOTP || isLogging)"> {{t('auth.title.verify_now')}}
              </span>
              <div *ngIf="isVerifyingOTP || isLogging" style="padding: 8px;">
                <mat-spinner style="margin: auto;" [diameter]="20"></mat-spinner>
              </div>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
