/**
 * Copyrights Reserved 2023 SPARK Technologies!
 *
 * @author SPARK Technologies
 * @category Auth
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { HttpClient, HttpParams, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SparkConfigService } from 'src/@spark/services/config/config.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  baseUrl = environment.APIUrl;
  mainUrl = environment.APIMainUrl;
  constructor(private _http: HttpClient, private _configService: SparkConfigService,) {
    _configService.config$.subscribe((data) => {
      //this.baseUrl = data.apiUrl
    })
  }

  get(path) {
    return this._http.get(this.baseUrl + path);
  }

  download(path) {
    return this._http.get(this.baseUrl + path, { responseType: 'blob' });
  }

  getWithAuth(path, token) {
    return this._http.get(this.baseUrl + path, { headers: { 'Authorization': 'Bearer ' + token ?? localStorage.getItem('accessToken') } });
  }

  post(path, data) {
    return this._http.post(this.baseUrl + path, data);
  }

  postExternal(path, data) {
    return this._http.post(this.mainUrl + "external/" + path, data, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('accessToken') } });
  }
}
