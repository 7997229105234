/**
 * Copyrights Reserved 2023 SPARK Technologies!
 *
 * @author SPARK Technologies
 * @category Auth
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry, tap, timeout } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class HttpIntercept implements HttpInterceptor {
  constructor(
    private _toastr: ToastrService,
    private _router: Router) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Get current url
    const url = encodeURIComponent(window.location.href);
    return next.handle(request)
      .pipe(
        timeout(20000),
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse && event.body.success == 0) {
            switch (event.body.status_code) {
              case 200: // OK
                return event;

              /** Show toast */
              case 401: // UNAUTH
              case 404: // SERVICE UNAVAILABLE
              case 601: // USERNAME_NOT_VERIFIED
              case 604: // USER_NOT_VERIFIED
              case 605: // USER_BLOCKED
              case 606: // EMAIL_ALREADY_VERIFIED
              case 607: // EMAIL_NOT_VERIFIED
              case 609: // NO ACCESS
              case 652: // ACCOUNT DONT EXIST
              case 400: // BAD REQUEST
                this._toastr.error(event.body.message);
                break;

              /** Show toast and return event */
              case 608: // VALIDATION_ERROR
              case 603: // OTP_RESEND_LIMIT_REACHED
                this._toastr.error(event.body.message);
                return event;

              /** Show toast and redirect to page */
              case 650: // SYSTEM_DOWN
              case 503: // SYSTEM_DOWN
                this._toastr.error(event.body.message);
                this.redirectTo('/error/' + event.body.status_code + '?error=' + event.body.message + '&ref=' + url)
                break;
              case 500: // INTERNAL_SERVER_ERROR
                this._toastr.error(event.body.message);
                this.redirectTo('/error/' + event.body.status_code + '?error=Internal Server Error&ref=' + url)
                break;
              default:
                this.redirectTo('/error/' + event.body.status_code + '?error=' + event.body.message + '&ref=' + url)
                this._toastr.error('Unknown error');
                throw new HttpErrorResponse({
                  error: event.body.message,
                  status: event.body.status_code
                })
            }
          }

          return event;
        }),

        catchError((error: HttpErrorResponse) => {
          if (error.name.toString() == 'TimeoutError') {
            this._toastr.warning('Request Time out!');
          }
          else {
            switch (error.status) {
              case 404:
                this._toastr.error('Service not found');
                break;
              case 401:
                this._toastr.error('Unauthorized access');
                localStorage.removeItem('accessToken')
                break;
              case 429: // NO ACCESS
                this._toastr.error("Too many requests");
                break;
              case 500:
                this._toastr.error('Internal server error');
                break;
              default:
                this._toastr.error('Unknown error');
            }
          }
          console.log("Error while calling " + error.url)
          return throwError(error);
        })
      )
  }

  redirectTo(url) {
    const tree: UrlTree = this._router.parseUrl(window.location.pathname);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g?.segments;
    if ((s && s[0].path != 'error') || !s)
      this._router.navigateByUrl(url, { replaceUrl: true, skipLocationChange: true })
  }
}


export class DataNotFoundError extends Error {
  error = "Data not found";
}

export class UnknownError extends Error {
  error = "Something went wrong!";
}
