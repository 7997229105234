/**
 * Copyrights Reserved 2023 SPARK Technologies!
 *
 * @author SPARK Technologies
 * @category Auth
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, from, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpService } from '../common-service/http.service';
import { Idle } from '@ng-idle/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { UserService } from '../user/user.service';
import { UtilityService } from '../common-service/utility.service';
import { MessagingService } from '../messaging/messaging.service';
import { NotificationService } from '../messaging/notification.service';
import { User } from '@angular/fire/auth';

@Injectable()
export class AuthService {
  apiUrl = environment.APIUrl;
  private _authenticated: boolean = false;
  deviceInfo;

  public readonly user: Observable<User | null> = EMPTY;
  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private _httpService: HttpService,
    private _utility: UtilityService,
    private idle: Idle,
    private deviceService: DeviceDetectorService,
    private _messagingService: MessagingService,
    private _notificationService: NotificationService,
  ) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    // this.user = authState(this.auth);
    // this.user.subscribe((data) => {
    //     //console.log(data);
    // })

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  verifyUser(mobile) {
    return new Promise((resolve, reject) => {
      this._httpService.post("external/auth/checkDetailsForForgetPassword", { search_key: mobile, otp_type_id: 1 }).subscribe((data: any) => {

        if (data.success) {
          resolve(data.data)
        }
        else {
          reject(data);
        }
      }, (error) => {
        reject(error);
      });
    });
  }

  verifyEmail(id) {
    return new Promise((resolve, reject) => {
      this._httpService.post("public/verifyUserByEmailID", { verify_user: id }).subscribe((data: any) => {
        if (data.success) {
          //this.isVerified = true;
          resolve(data.data)
        }
        else {
          reject(data.status_code)
          //this._toastr.error("Email id doen't exist!")
        }
      }, (error) => {
        reject(500)
      });
    });
  }

  resendRegisterOTP(data) {
    return new Promise((resolve, reject) => {
      this._httpService.post("external/auth/resendOTP", data).subscribe((data: any) => {
        if (data.status_code = 200) {
          resolve(data)
        }
        else {
          reject(data);
        }
      }, (error) => {
        reject(error);
      });
    });
  }

  verifyOTP(data) {
    data.user_fcm_token = this._messagingService.token$ ?? ''
    data.browser_name = this.deviceInfo.browser
    data.browser_version = this.deviceInfo.browser_version
    data.os = this.deviceInfo.os
    data.os_version = this.deviceInfo.os_version
    data.device_type = '0'
    return new Promise((resolve, reject) => {
      this._httpService.post("external/auth/verifyOTP", data).subscribe((response: any) => {
        if (response.success == 1) {
          this._httpService.getWithAuth("external/common/user/token/expiresAt", response.data.api_token).subscribe((expiry: any) => {
            if (expiry.success == 1) {
              let cred = {
                'role': response.data.user_role_drc_key,
                'username': data.username,
                'password': response.data.uuid,
                'login_type': 0,
                'email_id': response.data.email_id,
                'full_name': response.data.full_name,
                'profile_token': data.username + "_" + "profile_token",
                'expires_at': expiry.data
              }
              if (this._utility.getCookie("personalSecret")) {
                let creds = JSON.parse(this._utility.decryptAES(this._utility.getCookie("personalSecret")))
                if (!creds.find((cr) => cr.username == cred.username)) {
                  creds.push({ ...cred })
                  this._utility.setCookie("personalSecret", JSON.stringify(creds))
                }
              }
              else {
                this._utility.setCookie("personalSecret", JSON.stringify([{ ...cred }]))
              }
              let tokenData = {
                token: response.data.api_token,
                refreshToken: response.data.refresh_token,
              }
              this._utility.setCookie(data.username + "_" + "profile_token", JSON.stringify(tokenData))
              resolve(response)
            }
            else
              resolve(expiry)
          }, (error) => {
            reject(error)
          })
        }
        else
          resolve(response)
      }, (error) => {
        reject(error)
      });
    });
  }

  setNewPassword(data) {
    return new Promise((resolve, reject) => {
      this._httpService.post("external/auth/setNewPassword", data).subscribe((data: any) => {
        if (data.success) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      });
    });
  }


  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(data): Observable<any> {
    data.user_fcm_token = this._messagingService.token$ ?? ''
    data.browser_name = this.deviceInfo.browser
    data.browser_version = this.deviceInfo.browser_version
    data.os = this.deviceInfo.os
    data.os_version = this.deviceInfo.os_version
    data.device_type = '0'
    return this._httpService.post("external/auth/login", data).pipe(
      switchMap((response: any) => {
        return of(response);
      })
    );
  }

  signInWithGoogle(data) {
    let request: any = {};
    request.device_type = '0'
    request.user_fcm_token = this._messagingService.token$ ?? ''
    request.provider_id = data.providerId
    request.username = data.user.email
    request.email_id = data.user.email
    request.uid = data.user.uid
    request.access_token = data.accessToken
    request.user_role_drc_key = data?.user_role_drc_key
    request.latitude = data?.latitude ?? ""
    request.longitude = data?.longitude ?? ""
    return this._httpService.post("external/auth/provider/register", request).pipe(
      switchMap((response: any) => {
        if (response.success == 1) {
          return from(this._httpService.getWithAuth("external/common/user/token/expiresAt", response.data.api_token)
            .pipe(switchMap((expiry: any) => {
              if (expiry.success == 1) {
                let cred = {
                  'role': response.data.user_role_drc_key,
                  'username': request.username,
                  'login_type': 1,
                  'uid': request.uid,
                  'provider_id': request.provider_id,
                  'email_id': request.email_id,
                  'full_name': response.data.full_name,
                  'profile_token': request.username + "_" + "profile_token",
                  'access_token': request.access_token,
                  'expires_at': expiry.data
                }
                if (this._utility.getCookie("personalSecret")) {
                  let creds = JSON.parse(this._utility.decryptAES(this._utility.getCookie("personalSecret")))
                  if (!creds.find((cr) => cr.username == cred.username)) {
                    creds.push({ ...cred })
                    this._utility.setCookie("personalSecret", JSON.stringify(creds))
                  }
                }
                else {
                  this._utility.setCookie("personalSecret", JSON.stringify([{ ...cred }]))
                }
                let tokenData = {
                  token: response.data.api_token,
                  refreshToken: response.data.refresh_token,
                }
                this._utility.setCookie(request.username + "_" + "profile_token", JSON.stringify(tokenData))
                return of(response);
              }
              else
                return of(expiry);
            }))
          )
        }
        return of(response);
      })
    );
  }


  /**
   * Sign out
   */
  signOut() {
    return new Promise((resolve, reject) => {
      localStorage.removeItem('accessToken')
      this._authenticated = false
      resolve({})
    })

  }

  signOutFromGoogle() {
    //signOut(this.auth)
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(data) {
    return new Promise((resolve, reject) => {
      this._httpService.postExternal('auth/register', data).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
        }
        else {
          reject(data)
        }
      })
    })
  }

  /**
   * Check Account
   *
   * @param email
   */
  checkAccount(email) {
    return new Promise((resolve, reject) => {
      this._httpService.postExternal('auth/provider/checkExist', { username: email }).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data.data)
        }
        else {
          reject(data)
        }
      })
    })
  }


  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: { email: string; password: string }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {

    // Check if the user is logged in
    if (this._authenticated) {
      //this.idle.watch()
      //this._notificationService.start()
      return of(true);
    }

    // Check the access token availability
    if (this.accessToken) {
      //this.idle.watch()
      //this._notificationService.start()
      return of(true);

    }
    return of(false);
  }
}
