import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from './auth.service';
import { AuthInterceptor } from './auth.interceptor';
import { NgIdleModule } from '@ng-idle/core';
import { environment } from 'src/environments/environment';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
@NgModule({
    imports: [
        HttpClientModule,
    ],
    providers: [
        AuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ]
})
export class AuthModule {
}
