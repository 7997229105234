import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from './pages/error-page/error-page.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./auth-pages/auth-pages.module').then(m => m.AuthPagesModule),
  },
  {
    path: '',
    data: {
      layout: 'empty'
    },
    children: [
      {
        path: 'error/:type',
        component: ErrorPageComponent,
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
