import { environment } from "src/environments/environment"

export interface config {
  logo1,
  logo2,
  logo1Dark,
  logo2Dark,
  apiUrl,
  theme,
  scheme,
  layout
}
export const sparkConfig: config = {
  logo1: 'assets/logo/logo_gradient02.svg',
  logo1Dark: 'assets/logo/logo_white.svg',
  logo2: 'assets/logo/iconmark_gradient02.svg',
  logo2Dark: 'assets/logo/iconmark_white.svg',
  theme: 'default',
  scheme: 'light',
  layout: 'default',
  apiUrl: environment.production ? 'https://thesparktech.thesparktech.com/api/' : 'https://thesparktech.sparktechdev.org/api/'
}
